<style lang="scss" scoped>
@import './login.scss';
</style>

<template>
  <div class="register" :style="{backgroundImage:(info.background_img ? 'url(' + info.background_img + ')' : 'url('+backgroundImage+')')}">
    <div class="login-logo">
      <img v-if="info.full_logo" src="info.full_logo" style="height: 38px" alt="" />
<!--      <img v-else src="../../assets/images/new_icon/logo.png" style="height: 38px" alt="">-->
    </div>
    <div class="register-con" v-if="!invitationToken"><!-- 表单注册 -->
      <Row>
        <i-col span="18" push="3">
          <div class="register-form-con">
            <p class="form-title-style">欢迎注册</p>
            <RegisterForm :invitationToken="invitationToken" :isCompany="isCompany"></RegisterForm>
          </div>
        </i-col>
      </Row>
      <div style="width:100%;">
        <p style="width:100%;text-align:center;margin-bottom:25px;color:#7a90a7">{{$t('common_copyright')}}</p>
        <div class="use-chrome" v-if="userAgent.indexOf('Chrome') < 0">
          {{$t('common_use_chrome') }}
        </div>
      </div>
    </div>
    <div v-else> <!-- 邀请码扫码注册 -->
      <div class="register-form-wx" v-if="!perfectInfo">
        <div class="register-wx" style="border-left: none;padding: 0 40px">
          <p class="wx-tit">邀请您加入{{ info?.name || '实战云' }}平台</p>
          <TeacherWxLogin v-if="!perfectInfo" @wxSuccess="wxSuc" @success="loginSuc" ></TeacherWxLogin>
          <p class="wx-tip">打开微信扫描二维码加入平台</p>
        </div>
      </div>

      <div class="perfect-info" v-else>
        <div class="top" >
          <div class="back">
            <span>重新扫码</span><Button @click="back">&lt; 返回</Button>
          </div>
          <div class="info">
            已授权登录成功，请先完善信息!
          </div>
        </div>
        <TeacherWxForm :appid="appid" :wxOpenId="wxOpenId" :unionid="unionid" :invitationToken="invitationToken" :isCompany="isCompany" @success="loginSuc" @checkInvitationFail="checkInvitationFail"></TeacherWxForm>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import RegisterForm from "@/views/guest/components/registerForm";
import TeacherWxForm from "@/views/guest/components/teacherWxForm";
import TeacherWxLogin from '@/views/guest/components/teacherWxLogin';

export default {
  data () {


    return {
      lang: Cookies.get('lang') || 'zh-CN',
      userAgent: navigator.userAgent,
      isChrome: true,
      invitationToken:'',
      isCompany:false,
      appid:'',
      wxOpenId:'',
      unionid:'',
      perfectInfo:false,
      loading:false,
      info:{},
      backgroundImage:require('../../assets/images/new_icon/bj.png'),
    };
  },
  components:{
    RegisterForm,
    TeacherWxForm,
    TeacherWxLogin
  },
  created() {
    // this.fromBasicFinderWeb = this.$route.query.from === 'register_from_basicfinder_com';
    // if (this.fromBasicFinderWeb) {
    //   let _paq = window._paq = window._paq || [];
    //   _paq.push(['trackEvent', 'Register', 'visit', 'register_from_basicfinder_com']);
    // }

    //判断是否为邀请注册
    if(this.$route.params.id){
      this.invitationToken = this.$route.params.id;
      this.isCompany = true;
    }
    this.getInfo();
  },
  mounted () {
    //校验是否chrome浏览器
    let nav = window.navigator.userAgent;
    if (nav.includes('Chrome') || nav.includes('chrome')){
      this.isChrome = true
    } else {
      this.isChrome = false
    }
  },
  methods: {
    getInfo(){
      this.loading = true;
      this.api.index.indexInit().then((res)=>{
        this.loading = false;
        if(res.site) {
          this.info = res.site;
        }

      }).catch((err)=>{
        this.loading = false;
      })
    },
    wxSuc(data){  //微信扫码成功 完善信息
      this.perfectInfo = true;
      this.appid = data.appid;
      this.wxOpenId = data.wxOpenId;
      this.unionid = data.unionid;
    },
    loginSuc(){
      this.$store.commit('loginSuccessRouter',{form:this.$route.query.from,vm:this,fullPath:this.$route.query.from})
    },
    back(){
      this.perfectInfo = false;
    },
    checkInvitationFail(){
      this.perfectInfo = false;
    }
  }
};
</script>
